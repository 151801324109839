import React from 'react'

import Achievements from './Achievements/achievements'
import Badges from './Badges/badges'
import Interests from './Interests/interests'
import Languages from './Languages/languages'
import Skills from './Skills/skills'
import Education from './Education/education'

import './other-relevant-data.css'
import { Fragment } from 'react'

const OtherRelevantData = ({ otherRelevantData }) => (
  <Fragment>
    {/* {otherRelevantData.badges && (
      <Badges badges={otherRelevantData.badges}></Badges>
    )} */}
    {otherRelevantData.skills && (
      <Skills skills={otherRelevantData.skills}></Skills>
    )}
    {otherRelevantData.achievements && (
      <Achievements
        achievements={otherRelevantData.achievements}
      ></Achievements>
    )}
    {otherRelevantData.education && (
      <Education education={otherRelevantData.education}></Education>
    )}
    {/* {otherRelevantData.languages && (
      <Languages languages={otherRelevantData.languages}></Languages>
    )} */}
    {otherRelevantData.interests && (
      <Interests interests={otherRelevantData.interests}></Interests>
    )}
  </Fragment>
)

export default OtherRelevantData
