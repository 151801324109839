export const resumeData = {
  header: {
    name: 'Girija Swain',
    preferredName: 'Girija',
    summary: [
      '15 years of professional experience in solving business problems using the right technologies and people skills',
      '10 years of professional working experience with Fin-Techs across the globe',
      'Expertise in Cloud Native Event Driven Microservices Architecture, Domain Driven Design, Cloud Solution Architecture',
      'Enabler for continuous learning and bringing continuous improvements',
      'Strong programming skills in Java and JavaScript',
    ],
    social: {
      linkedInProfileUrl: 'https://www.linkedin.com/in/gsswain',
      stackOverflowProfileUrl:
        'https://stackoverflow.com/users/4601120/gsswain',
    },
    contact: {
      email: 'contact@gsswain.com',
      portfolioUrl: 'https://gsswain.com',
      mobile: '',
    },
  },
  professionalSummary: {
    employmentHistory: [
      {
        organization: 'Macquarie Group, Sydney',
        title: 'Lead Engineer',
        duration: {
          start: {
            month: 'Feb',
            year: '2022',
          },
          end: {
            month: 'Present',
            year: '',
          },
        },
        summary: `I'm contributing to the solution, architecture and delivery of the entitlements platform (Customer access management),
         which powers the online and mobile banking services and consents for open banking - Consumer Data Right (CDR).
         Below are some of the highlights: 
        <ul>
          <li> Spearheaded migration of millions of banking entitlement records to the strategic platform for personal & business banking.</li>
          <li> Implemented data reconciliation solutions to streamline processes and ensure accuracy. </li>
          <li> Introduced diagram as code for architecture documentation, enhancing clarity and efficiency.</li>
      </ul>
    `,
      },
      {
        organization: 'Insurance Australia Group (IAG), Sydney',
        title: 'Principal Engineer',
        duration: {
          start: {
            month: 'May',
            year: '2021',
          },
          end: {
            month: 'Feb',
            year: '2022',
          },
        },

        summary: `I worked as a consultant at Insurance Australia Group (IAG). Below are some of the highlights: 
       <ul>
          <li> Developed solutions for unified branding across Australia for IAG, i.e. NRMA in all states. </li>
          <li> Implemented code coverage reports in CI pipeline and created CI/CD pipelines for UI applications. </li>
          <li> Migrated application workloads from RHEL-7 to RHEL-8. </li>
     </ul>
   `,
      },
      {
        organization: 'Aegon Life Insurance Company (ALIC), India',
        title: 'Principal Engineer',
        duration: {
          start: {
            month: 'Feb',
            year: '2019',
          },
          end: {
            month: 'Dec',
            year: '2020',
          },
        },
        summary: `I architected and built a cloud native policy administration solution on AWS with a lot of agility in a startup like environment. Below are some of the highlights:
            <ul>
              <li> Collaborated with CTO and key stakeholders to design and build a cloud native policy administration solution on AWS from scratch. </li>
              <li> Built a multi-tenant policy administration system with B2B insurance sales via APIs, enabling onboarding of e-commerce platforms which helped increase the customer base and the brand recognition. </li>
              <li> Built the solution which supported policy quotations, policy proposals, policy renewals, customer data and entitlemennts management, customer correspondences for retail, group and employer lines of business (which includes all the software application tiers of apis, presentation layer, core services, integrations, transactional databases and data products for data lake) </li>
              <li> Led and mentored multiple squads of software engineers from Aegon Life, Saksoft & Thoughtworks. </li>
              <li> Grew the engineering team in quantity and quality. </li>
            </ul>
          `,
      },
      // {
      //   organization: 'T.I.A.A. Global Business Services (TIAA GBS), India',
      //   title: 'Associate',
      //   duration: {
      //     start: {
      //       month: 'Jul',
      //       year: '2017',
      //     },
      //     end: {
      //       month: 'Oct',
      //       year: '2018',
      //     },
      //   },
      // },
      // {
      //   organization: 'J.P. Morgan Services India (JPMC), India',
      //   title: 'Associate',
      //   duration: {
      //     start: {
      //       month: 'Feb',
      //       year: '2016',
      //     },
      //     end: {
      //       month: 'May',
      //       year: '2017',
      //     },
      //   },
      // },
      // {
      //   organization: 'L&T Infotech (LTI), India',
      //   title: 'Software Associate',
      //   duration: {
      //     start: {
      //       month: 'Dec',
      //       year: '2013',
      //     },
      //     end: {
      //       month: 'Feb',
      //       year: '2016',
      //     },
      //   },
      // },
      // {
      //   organization: 'Tata Consultancy Services (TCS), India',
      //   title: 'Systems Engineer',
      //   duration: {
      //     start: {
      //       month: 'Aug',
      //       year: '2010',
      //     },
      //     end: {
      //       month: 'Dec',
      //       year: '2013',
      //     },
      //   },
      // },
    ],
  },
  otherRelevantData: {
    badges: [
      {
        id: 'aws-sa',
        name: "Girija's AWS Solution Architect badge",
        verificationUrl:
          'https://www.credly.com/badges/c927fef9-e5ee-4e73-a1c5-dfbb5dd31d40/public_url',
        period: {
          from: 'Jul 2018',
          validTill: 'Feb 2025',
        },
      },
      {
        id: 'azure-fu',
        name: "Girija's Azure Fundamentals badge",
        verificationUrl:
          'https://www.credly.com/badges/6f8283a0-a895-4d7c-8d9a-5368d1433248/public_url',
      },
    ],
    skills: [
      {
        name: 'Microservices',
        proficiency: 4,
      },
      {
        name: 'Serverless',
        proficiency: 4,
      },
      {
        name: 'Cloud Solution Architecture',
        proficiency: 4,
      },
      {
        name: 'Java',
        proficiency: 4,
      },
      {
        name: 'JavaScript',
        proficiency: 4,
      },
      ,
    ],
    education: [
      {
        institute: 'Indira Gandhi National Open University',
        degree: 'Master of Computer Applications',
        //   percentage: '63',
        graduatedOn: 'June 2016',
      },
      {
        institute: 'Berhampur University',
        degree: 'Bachelors in Computer Applications',
        //   percentage: '83',
        graduatedOn: 'June 2010',
      },
    ],
    achievements: [
      '5K+ reputation on Stack Overflow (Top 7% globally)',
      'Annual Team Performance Award (2019-2020)',
      'Quarterly Team Award (2019)',
      'Quarterly Individual Performance Award (2018)',
      'Team Performance Award (2015)',
      // 'College Topper Award',
    ],
    languages: [
      {
        name: 'English',
        proficiency: 5,
      },
      {
        name: 'Odia',
        proficiency: 5,
      },
      {
        name: 'Hindi',
        proficiency: 4,
      },
    ],
    interests: [
      'Playing & Following Cricket',
      'Travelling & Capturing Landscapes',
      'Riding bikes',
    ],
  },
  footer: {
    portfolioUrl: 'https://gsswain.com',
  },
}
